import React, { useEffect } from "react"
import { navigate, Link } from "gatsby"
import { isEDMode, getNested } from '@src/helper';
// Style Imports
import './styles.scss'
// SEO
import Seo from '@components/utility/SEO'
// Structural Imports
import Layout from "@components/structure/Layout.jsx"
// Content Imports
import Hero from "@components/content/hero/Hero.jsx"
import Image from "@components/content/fractals-image.jsx"

const RegistrationConfirmed = ({location}) => {
    useEffect(() => {
        if (!isEDMode  && !getNested(location,'state', 'success')) {
            navigate('/registration');
        }
    },[])

    return (
        <Layout addedClass="page--registration-confirmed">
            <Seo title="Thank You | LUPKYNIS&reg; (voclosporin)" description="" robots="noindex" canonicalURL="https://www.lupkynis.com/registration-confirmed" />
            <Hero addedClass="bg-coral hero--string">
                <div className="col-wrapper">
                    <div className="container">
                        <div className="hero__content">
                            <h1 className="heading heading--accent">Registration</h1>
                            <h2>Stay connected</h2>
                            <p>Sign up for the latest about LUPKYNIS, get information about living with lupus nephritis, and hear about Aurinia Alliance<sup>&reg;</sup>, a personalized support program.</p>
                        </div>
                        <div className="hero__image">
                            <Image data={{desktopSrc: 'registration/hero.png', mobileSrc: 'registration/hero--mobile.png'}} />
                        </div>
                    </div>
                </div>
            </Hero>
            <section className="bg-purple">
                <div className="container">
                    <h2>Success!</h2>
                    <p>Thank you for registering for updates from LUPKYNIS. If you opted in to learn more about Aurinia Alliance<sup>&reg;</sup> a Nurse Case Manager will contact you soon. If you have any questions about LUPKYNIS talk to your doctor to see if it's right for you.</p>
                    <p>Want to keep exploring?</p>
                    <Link to="/" className="button">Return to home</Link>

                </div>
            </section>
        </Layout>
    )
}

export default RegistrationConfirmed